import { Suspense } from 'react';
import { RouteObject } from 'react-router-dom';
import { lazyWithRetry } from '~utils/lazyRetry';

const Page = lazyWithRetry(() => import('./page'));
const Team = lazyWithRetry(() => import('./team/page'));

export const routes: RouteObject[] = [
  {
    index: true,
    element: <Suspense><Page /></Suspense>,
  },
  {
    path: 'team',
    element: <Suspense><Team /></Suspense>,
  },
];
