import { LinkProps } from '@mui/material/Link';
import { PaletteColorOptions, createTheme } from '@mui/material/styles';
import LinkBehavior from './LinkBehavior';

declare module '@mui/material/styles' {
  interface PaletteOptions {
    neutralWhite: PaletteColorOptions;
    secondaryText: PaletteColorOptions;
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    neutralWhite: true
    secondaryText: true
  }
}

const theme = createTheme({
  typography: {
    fontFamily: '"Poppins",sans-serif',
    fontSize: 14,
    h1: {
      fontWeight: 600,
      fontSize: '3rem',
    },
    h2: {
      fontWeight: 600,
      fontSize: '2rem',
    },
    h3: {
      fontWeight: 600,
      fontSize: '1.5rem',
    },
    h4: {
      fontWeight: 600,
      fontSize: '1.2rem',
    },
    h5: {
      fontWeight: 600,
      fontSize: '1rem',
    },
    h6: {
      fontWeight: 600,
      fontSize: '0.8rem',
    },
    button: {
      textTransform: 'none',
    },
  },
  shape: {
    borderRadius: 8,
  },
  palette: {
    primary: {
      main: '#591DFF',
      contrastText: '#FFFBFE',
      light: '#9B77FF',
    },
    background: {
      paper: '#fdf7fa',
    },
    text: {
      primary: '#1f2426',
      secondary: '#4e5051',
    },
    neutralWhite: {
      light: '#fff',
      main: '#fff',
      dark: '#fff',
      contrastText: '#fff',
    },
    secondaryText: {
      main: '#4e5051',
      light: '#7b7c7d',
      dark: '#323334',
      contrastText: '#ffffff',
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          backgroundColor: '#333333',
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          border: 'none',
          width: 240,
        },
      },
    },
    MuiLink: {
      defaultProps: {
        component: LinkBehavior,
        underline: 'hover',
        color: 'inherit',
        variant: 'body1',
      } as LinkProps,
    },
    MuiButtonBase: {
      defaultProps: {
        LinkComponent: LinkBehavior,
      },
    },
    MuiButton: {
      styleOverrides: {
        text: {
          ':hover': {
            textDecoration: 'underline',
            backgroundColor: 'transparent',
          },
        },
      },
    },
    MuiPaper: {
      defaultProps: {
        elevation: 0,
      },
      styleOverrides: {
        root: {
          padding: 16,
        },
        // outlined: (props) => ({
        //   borderColor: props.theme.palette.primary.light,
        // }),
      },
    },
    MuiToolbar: {
      defaultProps: {
        variant: 'dense',
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          background: 'white',
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: (props) => ({
          borderRadius: props.theme.shape.borderRadius,
          '&::before': {
            borderBottom: 'none !important',
          },
          '&::after': {
            borderBottom: 'none !important',
          },
          '&::hover::before': {
            borderBottom: 'none',
          },
        }),
        input: (props) => ({
          paddingTop: props.theme.spacing(1.5),
          paddingBottom: props.theme.spacing(1.5),
        }),
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: 0,
          marginRight: 5,
        },
      },
    },
    MuiRadio: {
      defaultProps: {
        size: 'small',
      },
    },
    MuiDialogTitle: {
      defaultProps: {
        variant: 'h3',
        color: 'text.secondary',
      },
    },
    MuiDialogContentText: {
      defaultProps: {
        variant: 'body1',
        paragraph: true,
      },
    },
    MuiDialog: {
      defaultProps: {
        maxWidth: 'lg',
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          height: 8,
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          fontSize: 14,
        },
      },
    },
  },
  transitions: {
    duration: {
      enteringScreen: 500,
      leavingScreen: 500,
    },
  },
});

export default theme;
