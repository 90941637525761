import { Suspense } from 'react';
import { RouteObject } from 'react-router-dom';
import { lazyWithRetry } from '~utils/lazyRetry';

const ProgramDetail = lazyWithRetry(() => import('./afiliateProgramId/page'));
const Index = lazyWithRetry(() => import('./page'));
const NewProgram = lazyWithRetry(() => import('./new/page'));

export const routes: RouteObject[] = [
  {
    index: true,
    element: <Suspense><Index /></Suspense>,
  },
  {
    path: 'new',
    element: <Suspense><NewProgram /></Suspense>,
  },
  {
    path: ':afiliateProgramId',
    element: <Suspense><ProgramDetail /></Suspense>,
  },
];
