import { Suspense } from 'react';
import { RouteObject } from 'react-router-dom';
import { lazyWithRetry } from '~utils/lazyRetry';
import { routes as afiliateRoutes } from './afiliates/routes';
import { routes as campaignRoutes } from './campaign/routes';
import { routes as discoverRoutes } from './discover/routes';
import { routes as reportsRoutes } from './reports/routes';

const Chat = lazyWithRetry(() => import('./chat/page'));
const Index = lazyWithRetry(() => import('./page'));

export const routes: RouteObject[] = [
  {
    index: true,
    element: <Suspense><Index /></Suspense>,
  },
  {
    path: 'discover',
    children: discoverRoutes,
  },
  {
    path: 'campaign',
    children: campaignRoutes,
  },
  {
    path: 'chat',
    element: <Suspense><Chat /></Suspense>,
  },
  {
    path: 'afiliates',
    children: afiliateRoutes,
  },
  {
    path: 'reports',
    children: reportsRoutes,
  },
];
