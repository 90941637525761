import { InMemoryCache } from '@apollo/client';
import { backendUrl } from './url';
import { rootQueryTypePolicy } from './util';

const cache = () => new InMemoryCache({
  typePolicies: {
    AccountMetricQuery: rootQueryTypePolicy([]),
    AfiliateProgramQuery: rootQueryTypePolicy(['list', 'listProgramUsers']),
    BrandQuery: rootQueryTypePolicy([]),
    CampaignQuery: rootQueryTypePolicy(['list', 'campaigns']),
    ChatQuery: rootQueryTypePolicy(['list', 'listMessages', 'chats', 'chatMessages']),
    CountryCityQuery: rootQueryTypePolicy([]),
    EcommerceQuery: rootQueryTypePolicy(['listProducts']),
    GenericEnumsQuery: rootQueryTypePolicy([]),
    InsightsQuery: rootQueryTypePolicy([]),
    MobileVersionQuery: rootQueryTypePolicy([]),
    PlatformQuery: rootQueryTypePolicy([]),
    PostulationQuery: rootQueryTypePolicy(['postulations', 'campaignPostulations']),
    ProductQuery: rootQueryTypePolicy(['list']),
    ReportQuery: rootQueryTypePolicy([]),
    ReviewsQuery: rootQueryTypePolicy([]),
    SubscriptionsQuery: rootQueryTypePolicy([]),
    UserQuery: rootQueryTypePolicy([]),
    WorkOrderQuery: rootQueryTypePolicy(['list']),
    WorkspaceQuery: rootQueryTypePolicy([]),
    CampaignDashboardStatsType: {
      keyFields: false,
    },
    BrandDashboardType: {
      keyFields: false,
    },
    ReviewSearch: {
      keyFields: false,
    },
    ReviewSummary: {
      keyFields: false,
    },
    ReviewFields: {
      keyFields: false,
    },
    QuotaDictType: {
      keyFields: false,
    },
    BreakdownType: {
      keyFields: false,
    },
    BreakdownResult: {
      keyFields: false,
    },
    Money: {
      keyFields: false,
      fields: {
        byThousands: {
          keyArgs: false,
        },
      },
    },
    DateTimeRangeType: {
      keyFields: false,
    },
    Currency: {
      keyFields: false,
    },
    FileFieldType: {
      keyFields: false,
      fields: {
        url: {
          read(existing) {
            if (existing) {
              const url = new URL(existing, backendUrl).toString();
              if (url.endsWith('/')) {
                return url.slice(0, -1);
              }
              return url;
            }
            return existing;
          },
        },
      },
    },
    DateFieldType: {
      keyFields: false,
    },
    PeriodCheckoutType: { keyFields: false },
    PeriodTax: { keyFields: false },
    ValuesWithPurchaseOrderType: { keyFields: false },
  },
});

export default cache;
