import { ApolloClient, split } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { getMainDefinition } from '@apollo/client/utilities';
import createUploadLink from 'apollo-upload-client/createUploadLink.mjs';
import AwaitLock from 'await-lock';
import { createClient } from 'graphql-ws';
import DefaultCache from './cache';
import { getRefreshToken, getToken, hasTokenExpired, logIn, logOut } from './states';
import { backendUrl, webSocketUrl } from './url';

const lock = new AwaitLock();

const rawRefresh = `mutation refreshToken($refreshToken: String!) {
  user {
    refreshToken(refreshToken: $refreshToken) {
      payload
      refreshExpiresIn
      token
      refreshToken
      __typename
    }
  }
}`;

const url = `${backendUrl}/graphql/`;
const refreshAuthToken = async () => {
  const refreshToken = getRefreshToken();
  const body = {
    operationName: 'refreshToken',
    variables: {
      refreshToken,
    },
    query: rawRefresh,
  };
  const refreshResponse = await fetch(url, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  });
  const refreshJSON = await refreshResponse.json();
  return refreshJSON.data?.user?.refreshToken;
};
const getValidToken = async () => {
  await lock.acquireAsync();
  try {
    const token = getToken();
    if (!token) return null;
    if (await hasTokenExpired()) {
      const refreshedToken = await refreshAuthToken();
      if (refreshedToken) {
        await logIn(refreshedToken);
        return getToken();
      }
      logOut();
    }
    return token;
  } finally {
    lock.release();
  }
};

const authLink = setContext(async (_, { headers }) => {
  const token = await getValidToken();
  if (!token) {
    return {
      headers,
    };
  }
  return {
    headers: {
      ...headers,
      Authorization: `JWT ${token}`,
      'Accept-Language': 'es',
    },
  };
});

const httpLink = createUploadLink({
  uri: url,
  credentials: 'omit',
});

const wsLink = new GraphQLWsLink(createClient({
  url: `${webSocketUrl}/graphql/`,
  connectionParams: async () => ({
    Authorization: await getValidToken(),
  }),
}));

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition'
      && definition.operation === 'subscription'
    );
  },
  wsLink,
  httpLink,
);

const client = new ApolloClient({
  link: authLink.concat(splitLink),
  cache: DefaultCache(),
  defaultOptions: {
    query: {
      notifyOnNetworkStatusChange: true,
    },
  },
  connectToDevTools: true,
});

export default client;
