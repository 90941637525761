import { Suspense } from 'react';
import { RouteObject } from 'react-router-dom';
import { lazyWithRetry } from '~utils/lazyRetry';

const Index = lazyWithRetry(() => import('./page'));
const CreateWorkOrder = lazyWithRetry(() => import('./work-order/new/page'));

export const routes: RouteObject[] = [
  {
    index: true,
    element: <Suspense><Index /></Suspense>,
  },
  {
    path: 'work-order/new',
    element: <Suspense><CreateWorkOrder /></Suspense>,
  },
];
