import { Suspense } from 'react';
import { RouteObject } from 'react-router-dom';
import { lazyWithRetry } from '~utils/lazyRetry';

const Index = lazyWithRetry(() => import('./page'));
const UserProfile = lazyWithRetry(() => import('./userId/page'));

export const routes: RouteObject[] = [
  {
    index: true,
    element: <Suspense><Index /></Suspense>,
  },
  {
    path: ':userId',
    element: <Suspense><UserProfile /></Suspense>,
  },
];
