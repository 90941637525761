import { Suspense } from 'react';
import { RouteObject } from 'react-router-dom';
import { lazyWithRetry } from '~utils/lazyRetry';

const WorkOrderProfile = lazyWithRetry(() => import('./page'));
const Checkout = lazyWithRetry(() => import('./checkout/page'));

export const routes: RouteObject[] = [
  { index: true, element: <Suspense><WorkOrderProfile /></Suspense> },
  { path: 'checkout', element: <Suspense><Checkout /></Suspense> },
];
