import { Suspense } from 'react';
import { RouteObject } from 'react-router-dom';
import { lazyWithRetry } from '~utils/lazyRetry';
import { routes as workOrderIdRoutes } from './workOrderid/routes';

const Index = lazyWithRetry(() => import('./page'));
const NewWorkOrder = lazyWithRetry(() => import('./new/userId/page'));

export const routes: RouteObject[] = [
  {
    index: true,
    element: <Suspense><Index /></Suspense>,
  },
  {
    path: ':workOrderId',
    children: workOrderIdRoutes,
  },
  {
    path: 'new/:userId',
    element: <Suspense><NewWorkOrder /></Suspense>,
  },
];
