import { ApolloClient, makeVar } from '@apollo/client';
import moment from 'moment';
import { Scalars } from '../graphqlTypeScript/graphql';

type LoginProps = {
  payload: Scalars['GenericScalar']['output'];
  refreshExpiresIn: Scalars['Int']['output'];
  refreshToken: Scalars['String']['output'];
  token: Scalars['String']['output'];
};

export const isLoggedIn = makeVar<boolean | null>(null);
export const isSidebarOpen = makeVar<boolean>(false);
export const isDrawerBig = makeVar<boolean>(true);

export const logIn = async (
  {
    token,
    refreshToken,
    refreshExpiresIn,
    payload,
  }: LoginProps,
  client?: ApolloClient<object>,
) => {
  localStorage.setItem('token', token);
  localStorage.setItem('refreshToken', refreshToken);
  localStorage.setItem('refreshExpiresIn', refreshExpiresIn.toString());
  const expiration = payload.exp - 120;
  localStorage.setItem('tokenExpiration', expiration.toString());
  await client?.resetStore();
  isLoggedIn(true);
};
export const getToken = () => localStorage.getItem('token');

export const logOut = (client?: ApolloClient<object>) => {
  localStorage.removeItem('token');
  localStorage.removeItem('refreshToken');
  localStorage.removeItem('refreshExpiresIn');
  localStorage.removeItem('tokenExpiration');
  isLoggedIn(false);
  client?.cache.reset();
};

export const getRefreshToken = () => localStorage.getItem('refreshToken');

export const hasTokenExpired = async () => {
  const expires = localStorage.getItem('tokenExpiration');
  if (expires === null) return true;
  return moment().unix() > Number(expires);
};

export const getFlows = async () => {
  const flows = localStorage.getItem('flows');
  if (flows === null) {
    return {};
  }
  return JSON.parse(flows);
};

export const setFlows = async (flows: object) => {
  localStorage.setItem('flows', JSON.stringify(flows));
};

export const setFlowDone = async (flowName: string) => {
  const oldFlows = await getFlows();
  const flows = { oldFlows, [flowName]: true };
  setFlows(flows);
};

export const getFlow = async (flowName: string) => {
  const flows = await getFlows();
  const flow = flows[flowName];
  return flow || false;
};
