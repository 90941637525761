import { Suspense } from 'react';
import { RouteObject } from 'react-router-dom';
import { lazyWithRetry } from '~utils/lazyRetry';

const Index = lazyWithRetry(() => import('./page'));
const Subscription = lazyWithRetry(() => import('./subscription/page'));

export const routes: RouteObject[] = [
  {
    index: true,
    element: <Suspense><Index /></Suspense>,
  },
  {
    path: 'subscription',
    element: <Suspense><Subscription /></Suspense>,
  },
];
