import { Suspense } from 'react';
import { RouteObject } from 'react-router-dom';
import { lazyWithRetry } from '~utils/lazyRetry';
import { routes as postulationRoutes } from './postulation/routes';

const Brief = lazyWithRetry(() => import('./brief/page'));
const Index = lazyWithRetry(() => import('./page'));

export const routes: RouteObject[] = [
  {
    index: true,
    element: <Suspense><Index /></Suspense>,
  },
  {
    path: 'brief',
    element: <Suspense><Brief /></Suspense>,
  },
  {
    path: 'postulation',
    children: postulationRoutes,
  },
];
