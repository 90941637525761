import { ApolloProvider } from '@apollo/client/react/context/ApolloProvider';
import '@mdxeditor/editor/style.css';
import { initMercadoPago } from '@mercadopago/sdk-react';
import { CssBaseline } from '@mui/material';
import ThemeProvider from '@mui/material/styles/ThemeProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { GoogleOAuthProvider } from '@react-oauth/google';
import isocountries from 'i18n-iso-countries';
import moment from 'moment';
import 'moment/locale/es';
import 'react-chat-elements/dist/main.css';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import * as yup from 'yup';
import client from '~apollo/client';
import { routes } from '~pages/routes';
import { appTheme } from '~styles';
import './index.css';

initMercadoPago(process.env.REACT_APP_MP_SUSCRIPTION_PUBLIC_KEY as string);

yup.setLocale({
  string: {
    min: ({ min }) => `Este campo tiene un mínimo de ${min} caracteres`,
    max: ({ max }) => `Este campo tiene un máximo de ${max} caracteres`,
    url: 'Ingrese una URL válida que empiece con https://',
  },
  mixed: {
    defined: 'Ingrese un valor',
    required: 'Este campo es obligatorio',
  },
});

isocountries.registerLocale(require('i18n-iso-countries/langs/es.json'));

const router = createBrowserRouter(routes);
function App() {
  const locale = 'es';
  if (moment.locale() !== locale) {
    moment.locale(locale);
  }
  return (
    <GoogleOAuthProvider clientId="663937048141-j9mjotcjhl3712o9tilnu7sul1hmlgpn.apps.googleusercontent.com">
      <ThemeProvider theme={appTheme}>
        <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={locale}>
          <ApolloProvider client={client}>
            <CssBaseline />
            <RouterProvider router={router} />
          </ApolloProvider>
        </LocalizationProvider>
      </ThemeProvider>
    </GoogleOAuthProvider>
  );
}

export default App;
