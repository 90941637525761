import { Suspense } from 'react';
import { RouteObject } from 'react-router-dom';
import { lazyWithRetry } from '~utils/lazyRetry';
import { routes as postulationIdRoutes } from './postulationId/routes';

const Index = lazyWithRetry(() => import('./page'));

export const routes: RouteObject[] = [
  {
    index: true,
    element: <Suspense><Index /></Suspense>,
  },
  {
    path: ':postulationId',
    children: postulationIdRoutes,
  },
];
